import React from "react";
import { PhotoGrid } from '../../../components/PhotoGrid';
import Layout from '../../../components/Layout';
import { Section } from '../../../components/Section';
import { graphql } from 'gatsby';
import Seo from "../../../components/Seo";

class Category extends React.Component {
    
    render() {
        let crumbs = [];
        crumbs.push([this.props.pageContext.typeSlug,this.props.pageContext.typeName]);
        if(this.props.pageContext.categoryGroup !== "hidden") crumbs.push([this.props.pageContext.categoryGroupSlug,this.props.pageContext.categoryGroupName])
        crumbs.push(this.props.pageContext.categoryName);

        return (
            <Layout location={this.props.location}>
                <Seo pathname={this.props.path} context={{type:this.props.pageContext.type, categoryGroup: this.props.pageContext.categoryGroup, category: this.props.pageContext.category}} />
                <div style={{paddingTop:"50px"}}>
                    {/* <span>{this.props.pageContext.data.name}</span> */}
                    {<Section crumbs={crumbs} />}
                    <PhotoGrid data={this.props.data} />
                </div>
            </Layout>
        )
    }
}

export default Category;

export const pageQuery = graphql`
    query CategoryAutoPage($maxPictures: Int, $category: String, $type: String, $onlyInSpotlight: Boolean, $photoGridSortOrder: [SortOrderEnum]) {
        appPhotoGridImages: allImageSharp(limit: $maxPictures, filter: {fields: {data: {sourceInstanceName: {eq: "pictures"}, category: {eq: $category}, type: {eq: $type}, inSpotlight: {eq: $onlyInSpotlight}}}}, sort: {fields: [fields___data___order], order: $photoGridSortOrder}) {
            edges {
                node {
                    ...PhotoGrid
                }
            }
        }
    }
`;


/*
import React from "react"
import { PhotoGrid } from '../../../components/PhotoGrid';
import { graphql } from "gatsby"
export default function Component(props) {
  //return props.data.fields.sku + props.params.name // highlight-line
  return props.params.type + ":" + JSON.stringify(props.data,null,4) // highlight-line
}


export const pageQuery = graphql`
    query CategoryAutoPage($maxPictures: Int, $category: String, $type: String, $onlyInSpotlight: Boolean, $photoGridSortOrder: [SortOrderEnum]) {
        appPhotoGridImages: allImageSharp(limit: $maxPictures, filter: {fields: {data: {sourceInstanceName: {eq: "pictures"}, category: {eq: $category}, type: {eq: $type}, inSpotlight: {eq: $onlyInSpotlight}}}}, sort: {fields: [fields___data___order], order: $photoGridSortOrder}) {
            edges {
                node {
                    ...PhotoGrid
                }
            }
        }
    }
`;
*/